.App {
  text-align: center;
}

.SelectStyle {
  padding-bottom: 5%;  
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
 
  background-color: #282c34;
 min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
/*
.container {
	max-width: 1200px;
}

.vertical-center {
  /*min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
 /* min-height: 100vh; /* These two lines are counted as one :-)       

 width: 100%;  
 min-height: 100vh;
 display: -webkit-box;
 display: -webkit-flex;
 display: -moz-box;
 display: -ms-flexbox;
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center;
 padding: 15px;
 position: relative;
 z-index: 1;
}
*/